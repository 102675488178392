import Phaser from 'phaser';

export class Gun extends Phaser.Physics.Arcade.Sprite {
    constructor(scene, x, y, username, userLocationsRef) {
        super(scene, x, y, 'gun');

        this.scene = scene;
        this.username = username;
        this.userLocationsRef = userLocationsRef;
        this.isHeld = false;
        this.dropletsRemaining = 10;

        scene.add.existing(this);
        scene.physics.add.existing(this);

        this.body.setCollideWorldBounds(true);
        this.body.setBounce(0.2);
        this.body.setDrag(100);
        this.body.setFriction(100);

        this.setScale(0.2);
        this.setDepth(2);
        this.setInteractive();
        scene.input.setDraggable(this);

        this.droplets = scene.physics.add.group();

        this.setupInputHandlers();

        this.scene.input.keyboard.on('keydown-X', () => {
            if (this.isHeld) {
                this.shoot();
            }
        });

        console.log('Physics Body:', this.body);
    }

    setupInputHandlers() {
        this.setInteractive();

        this.on('dragstart', (pointer) => {
            this.body.allowGravity = false;
            this.body.setVelocity(0, 0);
            this.isHeld = true;
        });

        this.on('drag', (pointer, dragX, dragY) => {
            const width = this.scene.scale.width;
            const position = this.userLocationsRef.current[this.username];
            if (!position) return;

            const flipX = this.x < width / 3;
            this.flipX = flipX;

            const x = position.x * this.scene.scale.width;
            const y = position.y * this.scene.scale.height;
            const widthDifferential = width / 4;

            const padding = flipX ? 55 : -55;
            this.x = x - widthDifferential + padding;
            this.y = y;
        });

        this.on('dragend', (pointer) => {
            this.body.allowGravity = true;
            this.isHeld = false;
        });
    }

    update() {
        if (!this || !this.scene || !this.droplets || !this.droplets.scene) return;
        const children = this.droplets?.getChildren();
        if (!children || children.length === 0) return;

        children.forEach((droplet) => {
            if (!droplet.active) {
                return;
            }

            droplet.update();

            if (
                droplet.x < 0 ||
                droplet.x > this.scene.scale.width ||
                droplet.y < 0 ||
                droplet.y > this.scene.scale.height
            ) {
                droplet.destroy();
            }
        });
    }

    shoot() {
        if (!this.isHeld) return;

        if (!this || !this.scene) return;

        const direction = this.x > this.scene.scale.width / 2 ? -1 : 1;
        const droplet = this.droplets.create(this.x, this.y, 'water-drop');
        droplet.setVelocityX(direction * 3000);

        this.dropletsRemaining--;
        if (this.dropletsRemaining <= 0) {
            this.droplets.getChildren().forEach(droplet => droplet.destroy());
            this.destroy();
        }
    }

    destroy(fromScene) {
        if (this.droplets) {
            this.droplets.getChildren().forEach(droplet => droplet.destroy());
        }
        super.destroy(fromScene);
    }
}

export default Gun;
