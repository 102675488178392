import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  getAllRooms as apiGetAllRooms,
  getRoom as apiGetRoom
} from '../services/room.service'
import {
  getChatMessages as apiGetMessages,
} from '../services/message.service'
import { getEmojiFromString } from './utils'

export const fetchExistingRooms = createAsyncThunk(
  'rooms/fetchexistingRooms',
  async () => {
    const res = await apiGetAllRooms()

    return res.rooms
  }
)

export const getCurrentRoomInfo = createAsyncThunk(
  'rooms/getCurrentRoomInfo',
  async (currentRoomName) => {
    return await apiGetRoom(currentRoomName)
  }
)

export const getChats = createAsyncThunk(
  'rooms/getChats',
  async (currentRoomName) => {
    const messages = (await apiGetMessages(currentRoomName));
    return messages;
  }
)


function hasRoomChanged(existingRoom, fetchedRoom) {
  // Compare relevant properties of the rooms
  // You can customize this comparison based on what properties are significant
  console.log("existing", existingRoom)
  console.log("fetched", fetchedRoom)
  return (
    existingRoom.ownerName !== fetchedRoom.ownerName ||
    existingRoom.active !== fetchedRoom.active ||
    existingRoom.userNames.length !== fetchedRoom.userNames.length
  );
}

const initialState = {
  loading: false,
  username: '',
  rooms: [],
  currentRoom: {
  },
  currentGame: {
    score: {}
  },
  chatMessages: [],
  showGameResults: false,
  userLocations: {},
  userEmojis: {},
  backgroundColor: "#282a36",
  theme: "color"
}

export const gameSlice = createSlice({
  name: 'game',
  initialState,
  reducers: {
    updateUsername: (state, action) => {
      state.username = action.payload
      state.userEmojis[state.username] = getEmojiFromString(state.username);
    },
    gameStart: (state, { payload: { type: _type, ...payload } }) => {
      state.showGameResults = false

      state.currentGame = {
        ...payload,
        results: [],
        score: {}
      }

      state.currentRoom = {
        ...state.currentRoom,
        active: true
      }
    },
    gameEnd: (state, action) => {
      state.currentGame = {
        ...state.currentGame,
        score: {},
        results: action.payload.results
      }

      state.currentRoom = {
        ...state.currentRoom,
        active: false
      }

      state.showGameResults = true
    },
    roomSettingsUpdate: (state, action) => {
      state.backgroundColor = action.payload.backgroundColor;
      state.theme = action.payload.theme;
      state.backgroundImage = action.payload.backgroundImage;
    },
    gameUserJoined: (state, action) => {
      state.currentRoom = {
        ...state.currentRoom,
        userNames: [...new Set([...(state.currentRoom.userNames || []), action.payload.userName, state.username])]
      }
      state.userEmojis[action.payload.userName] = getEmojiFromString(action.payload.userName);
    },
    gameUserLeft: (state, action) => {
      const userName = action.payload.userName;
      const newUserLocations = { ...state.userLocations };
      delete newUserLocations[userName];
      delete state.userEmojis[userName];
      state.userLocations = { newUserLocations }
      state.currentRoom = {
        ...state.currentRoom,
        userNames: state.currentRoom.userNames.filter(n => n != action.payload.userName)
      }
    },
    chatMessageReceived: (state, action) => {
      state.chatMessages.push({
        userName: action.payload.userName,
        message: action.payload.message,
        timestamp: action.payload.timestamp,
      });
    },
    prependChatMessages: (state, action) => {
      state.chatMessages = [...action.payload.messages.sort((a, b) => a.timestamp - b.timestamp), ...state.chatMessages];
    },
    mouseMovementUpdate: (state, action) => {
      const username = action.payload.userName;
      const { x, y } = action.payload.coordinate;
      state.userLocations = state.userLocations ?? {};
      state.userLocations[username] = { x, y };
    },
    gameTapUpdate: (state, action) => {
      const updatedScore = { ...state.currentGame.score }

      if (!state.currentRoom.active) {
        return;
      }

      action.payload.taps.forEach(({ userName, tapDelta }) => {
        updatedScore[userName] = (updatedScore[userName] || 0) + (tapDelta == 0 ? 1 : tapDelta)
      })

      state.currentGame = {
        ...state.currentGame,
        score: updatedScore
      }
    },
    closeGameResultsModal: (state) => {
      state.showGameResults = false
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchExistingRooms.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchExistingRooms.fulfilled, (state, action) => {
        const fetchedRooms = action.payload;
        const existingRoomsMap = new Map(state.rooms.map(room => [room.roomName, room]));
        const updatedRooms = [];

        state.rooms = fetchedRooms;
        state.loading = false
      })
      .addCase(fetchExistingRooms.rejected, (state) => {
        state.loading = false
      })
      .addCase(getChats.pending, (state) => {
        state.chatsLoading = true
      })
      .addCase(getChats.fulfilled, (state, action) => {
        state.chatsLoading = false
        state.chatMessages = state.chatMessages ?? []
        state.chatMessages = action.payload.messages.sort((a, b) => a.timestamp - b.timestamp)
      })
      .addCase(getChats.rejected, (state) => {
        state.chatsLoading = false
      })
      .addCase(getCurrentRoomInfo.pending, (state) => {
        state.loading = true
      })
      .addCase(getCurrentRoomInfo.fulfilled, (state, action) => {
        state.loading = false
        const usernames = [...new Set([...(action.payload.userNames || []), state.username])];
        const usernameToEmojiMap = usernames.reduce((map, username) => {
          map[username] = getEmojiFromString(username);
          return map;
        }, {});
        state.userEmojis = usernameToEmojiMap;
        state.currentRoom = {
          ...action.payload,
          userNames: usernames,
        }
        state.backgroundColor = action.payload.backgroundColor;
        state.theme = action.payload.backgroundThemePreset;
        state.backgroundImage = action.payload.backgroundImage;
      })
      .addCase(getCurrentRoomInfo.rejected, (state) => {
        state.loading = false
      })
  }
})

// Action creators are generated for each case reducer function
export const { updateUsername, gameStart, gameEnd, gameUserLeft, gameUserJoined, gameTapUpdate, mouseMovementUpdate, closeGameResultsModal, chatMessageReceived, roomSettingsUpdate, prependChatMessages } = gameSlice.actions

export default gameSlice.reducer
