import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { BarChart } from '@mui/x-charts';
import { useDispatch, useSelector } from 'react-redux'
import { closeGameResultsModal } from '../redux/gameSlice'

export default () => {
  const dispatch = useDispatch()
  const { currentGame, showGameResults } = useSelector(state => state.game)

  const closeModal = () => dispatch(closeGameResultsModal())

  const scores = [...(currentGame.results || [])].sort((a, b) => b.tapCount - a.tapCount)

  if (!showGameResults) return

  if (!scores.length) {
    scores.push({ userName: 'No one', tapCount: 0 })
  }

  return (
    <Dialog open maxWidth="xs" fullWidth PaperProps={{ sx: { overflow: 'visible' } }}>
      <DialogTitle style={{ position: 'absolute', marginTop: '-60px', width: '100%', textAlign: 'center' }}>
        <Typography variant='h1'>
          Game Over
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant='h2' sx={{ textAlign: 'center' }}>
          {scores[0].userName} wins!
        </Typography>
        <BarChart
          height={200}
          dataset={scores}
          borderRadius={4}
          xAxis={[
            {
              max: scores[0].tapCount,
              hideTooltip: true
            }
          ]}
          series={[
            {
              dataKey: 'tapCount',
              hideTooltip: true
            },
          ]}
          yAxis={[
            {
              title: 'Players',
              dataKey: 'userName',
              scaleType: 'band',
              hideTooltip: true,
              disableTicks: true,
              disableLine: true
            },
          ]}
          bottomAxis={null}
          barLabel='value'
          layout='horizontal'
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal}>Continue</Button>
      </DialogActions>
    </Dialog>
  );
};

