export const getEmojiFromString = (text) => {
    const emojis = [
        "😀", "😂", "😍", "😎", "🤔", "😭", "🥳", "🤯", "😇", "🤠",
        "😱", "😈", "👻", "💀", "👽", "👾", "🤖", "🎃", "🎉", "🔥",
        "🌈", "🌞", "🌜", "🌍", "⭐", "⚡", "🍕", "🍔", "🍟", "🍩",
        "🥶", "🥵", "😡", "😢", "😴", "🤩", "🙃", "🤪", "🤑", "🥴",
        "🤥", "🤫", "🤭", "🧐", "😷", "🤧", "🥱", "🤠", "🥸", "👹",
        "👺", "👿", "💩", "👽", "👾", "🤖", "👺", "🙈", "🙉", "🙊",
        "🍎", "🍏", "🍇", "🍉", "🍓", "🍒", "🍑", "🍍", "🥥", "🥑",
        "🍆", "🥒", "🌽", "🍿", "🍰", "🍮", "🎂", "🍨", "🍦", "🥧",
    ]

    if (!text) {
        return '❓';
    }
    let hash = 0;
    for (let i = 0; i < text.length; i++) {
        hash = text.charCodeAt(i) + ((hash << 5) - hash);
    }
    const index = Math.abs(hash) % emojis.length;

    return emojis[index];
}
