const BASE_URL = `https://${process.env.REACT_APP_API_URL}`

/**
 * Create a new room
 * @param {string} roomName - The name of the room to create
 * @param {string} ownerName - The name of the owner creating the room
 * @returns {Promise<Object>} - The response from the server
 */
export const createRoom = async (roomName, ownerName) => {
  try {
    const response = await fetch(`${BASE_URL}/rooms`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ roomName, ownerName }),
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    return response.json();
  } catch (error) {
    console.error('Failed to create room:', error);
    throw error;
  }
};

/**
 * Join an existing room
 * @param {string} roomName - The name of the room to join
 * @param {string} userName - The name of the user joining the room
 * @returns {Promise<Object>} - The response from the server
 */
export const joinRoom = async (roomName, userName) => {
  try {
    const response = await fetch(`${BASE_URL}/rooms/${roomName}/join`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userName }),
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    return response.json();
  } catch (error) {
    console.error('Failed to join room:', error);
    throw error;
  }
};

/**
 * Get the details of a specific room by name
 * @param {string} roomName - The name of the room to retrieve
 * @returns {Promise<Object>} - The room details from the server
 */
export const getRoom = async (roomName) => {
  try {
    const response = await fetch(`${BASE_URL}/rooms/${roomName}`, {
      method: 'GET',
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    return response.json();
  } catch (error) {
    console.error('Failed to get room details:', error);
    throw error;
  }
};

/**
 * Get all available rooms
 * @returns {Promise<Object>} - The list of rooms from the server
 */
export const getAllRooms = async () => {
  try {
    const response = await fetch(`${BASE_URL}/rooms`, {
      method: 'GET',
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    return response.json();
  } catch (error) {
    console.error('Failed to get rooms:', error);
    throw error;
  }
};

/**
 * Start a game in a specific room
 * @param {string} roomName - The name of the room to start the game in
 * @returns {Promise<Object>} - The response from the server
 */
export const startGame = async (roomName) => {
  try {
    const response = await fetch(`${BASE_URL}/rooms/${roomName}/start`, {
      method: 'POST',
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    return 200
  } catch (error) {
    console.error('Failed to start game:', error);
    throw error;
  }
};

export const sendChatMessage = async (roomName, userName, message) => {
  try {
    const response = await fetch(`${BASE_URL}/rooms/${roomName}/message`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userName, message }),
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    return response.json();
  } catch (error) {
    console.error('Failed to send chat message:', error);
    throw error;
  }
};

export const updateRoomSettings = async (roomName, backgroundColor, backgroundThemePreset, backgroundImage) => {
  try {
    const response = await fetch(`${BASE_URL}/rooms/${roomName}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ backgroundColor, backgroundThemePreset, backgroundImage }),
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    return response.json();
  } catch (error) {
    console.error('Failed to update room:', error);
    throw error;
  }
};
