import { useEffect, useState, useRef } from 'react';
import { fetchExistingRooms } from '../../redux/gameSlice';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Divider,
  Box,
  Card,
  CardContent,
  Typography,
  Link,
  ButtonBase,
  useMediaQuery,
  IconButton
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { createRoom } from '../../services/room.service';
import AddIcon from '@mui/icons-material/Add';
import Circle from '@mui/icons-material/Circle';
import { useTheme } from '@mui/material/styles';
import logo from '../../assets/logo.png';
import Navbar from '../../components/Navbar';
import { confetti } from '@tsparticles/confetti';
import { getNormalizedMousePosition } from '../RoomPage/utils';
import { getRandomMessage, playerCountMessages, emptyRoomMessages } from './utils';
import CloseIcon from '@mui/icons-material/Close'
import SaveIcon from '@mui/icons-material/Save'

export default () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const { username, rooms } = useSelector((state) => state.game);
  const [isOpenCreateRoomDialog, setIsOpenCreateRoomDialog] = useState(false);
  const [roomName, setRoomName] = useState('');
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleCardEnterEffectLockRef = useRef(null)


  function handleCardEnter(event, active) {
    const { normalizedX, normalizedY } = getNormalizedMousePosition(event);

    if (active || handleCardEnterEffectLockRef.current) {
      return;
    }

    confetti({
      particleCount: 400,
      spread: 4000,
      origin: { x: normalizedX, y: normalizedY },
      gravity: 5,
      flat: true,
    });

    handleCardEnterEffectLockRef.current = setTimeout(() => {
      handleCardEnterEffectLockRef.current = null
    }, 1000)
  }

  useEffect(() => {
    let timeout;
    const refreshRooms = () => {
      dispatch(fetchExistingRooms());
      timeout = setTimeout(refreshRooms, 5000);
    };
    refreshRooms();
    return () => clearTimeout(timeout);
  }, []);

  const handleKeyDown = (event) => {
    if (event.key !== 'Enter' || !roomName.length) return;
    handleCreateRoom();
  };

  const handleCreateRoom = async () => {
    try {
      await createRoom(roomName, username);
      dispatch(fetchExistingRooms());
      setIsOpenCreateRoomDialog(false);
      navigate(`/rooms/${roomName}`);
    } catch (error) {
      console.error('Failed to create room:', error);
    }
  };

  const handleCardClick = (roomName, active) => {

    if (active) {
      return;
    }
    navigate(`/rooms/${roomName}`);
  };

  const sortedRooms = [...rooms].sort((a, b) => b.userNames.length - a.userNames.length);


  function stringToHexColor(input) {
    let hash = 0;
    for (let i = 0; i < input.length; i++) {
      hash = input.charCodeAt(i) + ((hash << 5) - hash);
    }
    const color = `#${((hash >> 0) & 0xffffff).toString(16).padStart(6, '0')}`;
    return color;
  }


  return (
    <Box width={"100%"}
    >
      <Navbar />

      <Box
        ml={"10%"}
        mr={"10%"}>
        <Dialog
          open={isOpenCreateRoomDialog}
          onClose={() => setIsOpenCreateRoomDialog(false)}
          fullWidth
          sx={{}}
          PaperProps={{
            class: 'glowing-dialog',
            sx: {
              width: '40em',
            },
          }}
        >
          <DialogTitle>
            <Typography color='white' variant='h2' paddingBottom="25">Ready to host the ultimate showdown?</Typography>
          </DialogTitle>
          <DialogContent>

            <Typography color='white' variant='h3' sx={{ fontWeight: '200' }} paddingBottom="25px"> When you create a room, you're crafting a battleground where confetti flies and champions rise! 🎉</Typography>
            <Typography color='white' variant='h5' sx={{ fontWeight: '200' }} paddingBottom="75px">Here's the deal: Your room is open to everyone, so anyone can join in on the fun. Whether you're planning an epic match with friends or letting strangers join the chaos, your room is the place to be. Set the stage, pick your settings, and get ready for an explosion of confetti-fueled excitement! Time to create your room and let the games begin!</Typography>
            <TextField
              autoFocus
              label="Room Name"
              type="text"
              fullWidth
              variant="outlined"
              value={roomName}
              onChange={(e) => setRoomName(e.target.value)} // Update room name state
              onKeyDown={handleKeyDown}
              zIndex="20"
            />
          </DialogContent>
          <DialogActions >
            <Button variant="outlined" onClick={() => setIsOpenCreateRoomDialog(false)} sx={{ color: "white", }} >
              <CloseIcon />
            </Button>
            <Button variant="outlined" onClick={handleCreateRoom} sx={{ color: "white", mr: '1em' }} >
              <SaveIcon />
            </Button>
          </DialogActions>
        </Dialog>
        <Box
          sx={{
            display: 'flex',
            justifyContent: isMobile ? 'center' : 'flex-end',
            mt: 4,
            pr: 2,
          }}
        >
          {username?.length > 0 && <Button
            variant="outlined"
            color="primary"
            onClick={() => setIsOpenCreateRoomDialog(true)}
          >
            <AddIcon />
          </Button>}
        </Box>
        {sortedRooms.length === 0 ? (
          <Box sx={{ mt: 4 }}>
            <Typography variant="h5">
              No rooms yet. Why not{' '}
              <Link onClick={() => setIsOpenCreateRoomDialog(true)} sx={{ cursor: 'pointer' }}>
                create one
              </Link>
              ?
            </Typography>
          </Box>
        ) : (
          <Box
            sx={{
              minWidth: '80%',
              display: 'grid',
              gridTemplateColumns: {
                xs: '1fr',
                sm: '1fr',
                md: 'repeat(3, 1fr)',
                lg: 'repeat(4, 1fr)',
              },
              gap: 3,
              pt: 2,
            }}
          >
            {sortedRooms.map((room) => (
              <ButtonBase
                key={room.roomName}
                zIndex={10}
                onClick={() => handleCardClick(room.roomName, room.active)}
                sx={{ textAlign: 'left', borderRadius: 2 }}
              >
                <Card
                  class={room.active ? 'disabledRoom' : 'activeRoom'}
                  zIndex={999}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 999,
                    boxShadow: 3,
                    borderRadius: 2
                  }}
                >
                  <CardContent
                    zIndex={999}
                    onMouseEnter={(event) => handleCardEnter(event, room.active)}
                    sx={{
                      position: 'relative',
                      textAlign: 'center',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      borderRadius: 2,
                      p: 4,
                      boxShadow: 3,
                      zIndex: 9,
                      minHeight: "450px",
                      border: "2px solid rgba(255, 255, 255, 0.12)",
                      color: "primary",
                      '&:hover': {
                        boxShadow: 6,
                      },
                    }}
                  >

                    <Box height={"50%"}>
                      <Typography
                        variant="h1"
                        component="div"
                        sx={{
                          color: room.backgroundColor ?? stringToHexColor(room.roomName),
                          display: 'inline-flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          fontWeight: 500,
                          fontSize: '3rem',
                        }}
                      >
                        {room.roomName}
                      </Typography>
                      <Typography
                        color="textSecondary"
                        sx={{ mt: 1.5, mb: 1.5, fontSize: '1.1rem' }}
                      >
                        👑 {room.ownerName} 👑
                      </Typography>
                    </Box>
                    <Divider />

                    {(!room.active && room.userNames.length > 0) && (<Box mt={"1em"} height={"50%"}><Typography class="glowinggreen" sx={{ mt: 2, color: 'green' }}><Circle /></Typography><Typography
                      variant="body1"
                      sx={{
                        mt: "1em",
                        fontSize: '1.5rem',
                        fontWeight: '300',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: '#c6ff79'
                      }}>
                      {room.userNames.length} {getRandomMessage(playerCountMessages, room.roomName)}
                    </Typography></Box>)}

                    {(!room.active && room.userNames.length <= 0) && (<Box mt={"1em"} ><Typography sx={{ mt: 2 }}><Circle /></Typography><Typography
                      variant="body1"
                      sx={{
                        mb: 1,
                        mt: "1em",
                        fontSize: '1.5rem',
                        fontWeight: '300',
                        display: 'inline-flex',
                        alignItems: 'center',
                        textAlign: 'center',
                        justifyContent: 'center',
                      }}>
                      {getRandomMessage(emptyRoomMessages, room.roomName)}
                    </Typography></Box>)}

                    {/* Game in progress status */}
                    {room.active && (
                      <Box mt={"1em"}>
                        <Typography class="glowingred" sx={{ mt: 2 }}><Circle /></Typography>
                        <Typography
                          color="error"
                          sx={{
                            fontSize: '1.5rem',
                            fontWeight: '200',
                            display: 'inline-flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            color: 'red'
                          }}
                        >
                          🚨 Room closed until the confetti stops flying
                        </Typography>
                      </Box>
                    )}
                  </CardContent>
                </Card>
              </ButtonBase>
            ))}
          </Box>
        )
        }
      </Box>
    </Box >
  );
};