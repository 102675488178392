// src/components/Chat/index.js

import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, TextField, Button, Typography } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { sendChatMessage } from '../../services/room.service';
import { chatMessageReceived, prependChatMessages } from '../../redux/gameSlice';
import CircularProgress from '@mui/material/CircularProgress';
import { getChatMessages } from '../../services/message.service'

const CHAT_FETCH_SIZE = 25;

const Chat = ({ roomName, username, textColor }) => {
    const dispatch = useDispatch();
    const { userEmojis, chatMessages } = useSelector(state => state.game);
    const [message, setMessage] = useState('');
    const [hasMoreMessages, setHasMoreMessages] = useState(true)
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const [page, setPage] = useState(1);
    const messagesEndRef = useRef(null);
    const messagesContainerRef = useRef(null);


    const fetchOlderMessages = async () => {
        if (isLoadingMore) return;
        setIsLoadingMore(true);

        try {
            const olderMessages = await getChatMessages(roomName, page * CHAT_FETCH_SIZE, CHAT_FETCH_SIZE);
            console.log("Page!", page);
            if (olderMessages.messages.length > 0) {
                const newPage = page + 1;
                console.log('setting new page', newPage)
                dispatch(prependChatMessages(olderMessages));
                setPage(newPage);
            } else {
                console.log("nah no more.")
                setHasMoreMessages(false);
            }
        } catch (error) {
            console.error('Failed to load older messages:', error);
        } finally {
            setIsLoadingMore(false);
        }
    };

    const handleScroll = () => {
        const container = messagesContainerRef.current;
        if (container.scrollTop === 0 && hasMoreMessages) {
            fetchOlderMessages();
        }
    };

    useEffect(() => {
        const container = messagesContainerRef.current;
        if (container) {
            container.addEventListener('scroll', handleScroll);
        }
        return () => {
            if (container) {
                container.removeEventListener('scroll', handleScroll);
            }
        };
    }, [hasMoreMessages]);

    useEffect(() => {
        if (!isLoadingMore) {
            messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
        }
    }, [chatMessages, isLoadingMore]);


    // Scroll to the bottom when messages change
    // useEffect(() => {
    //     messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    // }, [chatMessages]);

    function msToTime(epochMs) {
        const date = new Date(epochMs);
        const options = {
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: true,
        };
        return date.toLocaleString('en-US', options);
    }


    const handleSendMessage = async () => {
        if (message.trim() === '') return;
        try {
            await sendChatMessage(roomName, username, message);
            dispatch(
                chatMessageReceived({
                    userName: username,
                    message: message,
                    timestamp: Date.now(),
                })
            );
            setMessage('');
        } catch (error) {
            console.error('Failed to send message:', error);
        }
    };

    const handleKeyPress = async (event) => {
        if (event.key === 'Enter') {
            await handleSendMessage();
        }
    };

    function stringToHexColor(input) {
        let hash = 0;
        for (let i = 0; i < input.length; i++) {
            hash = input.charCodeAt(i) + ((hash << 5) - hash);
        }
        const color = `#${((hash >> 0) & 0xffffff).toString(16).padStart(6, '0')}`;
        return color;
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }} >
            <Box mt={1} mb={1} >
                <Typography
                    color={textColor}
                    variant="h4"
                >
                    Chat
                </Typography>
            </Box>
            <Box
                ref={messagesContainerRef}
                borderRadius={2}
                p={2}
                sx={{
                    flexGrow: 1,
                    overflowY: 'auto',
                    backgroundColor: 'rgba(227, 225, 225, 0.9)',
                }}
            >
                {isLoadingMore && (
                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 1 }}>
                        <CircularProgress size={24} />
                    </Box>
                )}
                {chatMessages?.length == 0 && < Typography
                    justifyContent='vertical'
                    align='center'
                    color="grey"
                    mt={14}
                    p={1}
                    variant="h6"> The battle of words is quiet… 🎉 Start the confetti storm by sending a message!
                </Typography>}
                {chatMessages?.length > 0 &&
                    chatMessages.map((msg, index) => (
                        <Box key={index} mb={2}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}
                            >
                                <Typography
                                    color={stringToHexColor(msg.userName)}
                                    variant="h6"
                                >
                                    <strong>
                                        {userEmojis[msg.userName]} {msg.userName}
                                    </strong>
                                </Typography>
                                <Typography
                                    color="grey"
                                    variant="caption"
                                    sx={{ marginLeft: '1rem' }}
                                >
                                    {msToTime(msg.timestamp)}
                                </Typography>
                            </Box>
                            <Typography color="black" variant="body1" gutterBottom>
                                {msg.message}
                            </Typography>
                        </Box>
                    ))}
                <div ref={messagesEndRef} />
            </Box>

            {/* Message Input */}
            <Box sx={{ display: 'flex', mt: 1 }} columnGap={1}>
                <TextField
                    borderRadius={2}
                    size="medium"
                    sx={{
                        flex: 1,
                        '& .MuiInputBase-root': {
                            backgroundColor: 'rgba(227, 225, 225, 0.9)',
                        },
                        '& .MuiInputBase-input::placeholder': {
                            color: 'rgba(0, 0, 0, 0.5)',
                        },
                        '& .MuiInputBase-input': {
                            color: 'rgba(0, 0, 0, 0.85)',
                        },
                    }}
                    placeholder="Type your message..."
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    onKeyPress={handleKeyPress}
                    autoComplete="off"
                    multiline
                    maxRows={3}
                />
                <Button
                    onClick={handleSendMessage}
                    variant="outlined"
                    color={textColor}
                >
                    <SendIcon />
                </Button>
            </Box>
        </Box >
    );
};

export default Chat;