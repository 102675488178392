export const playerCountMessages = [
    "players ready to pop some confetti! 🎉",
    "confetti warriors in the arena! ✨",
    "players are gearing up for the confetti battle! 🎊",
    "players launching confetti madness! 🚀",
    "players spreading confetti joy! 🥳",
    "confetti champions are battling it out! 🏆",
    "players are causing confetti chaos! 🎉",
    "warriors in the confetti warzone! 💥",
    "players unleashing confetti storms! 🌪️",
    "contenders in the confetti clash! ⚔️",
    "players turning the arena colorful! 🌈",
    "confetti ninjas on the loose! 🥷",
    "players igniting the confetti fiesta! 🎊",
    "adventurers exploring confetti realms! 🗺️",
    "participants making confetti history! 📜",
    "players stirring up confetti fun! 🎭",
    "heroes fighting for confetti glory! 🦸",
    "players dancing in confetti showers! 💃",
    "challengers ready for confetti action! 🏹",
    "players spreading confetti magic! ✨",
    "confetti pilots soaring high! ✈️",
    "gamers diving into confetti adventures! 🤿",
    "confetti chefs cooking up fun! 🍳",
    "artists creating confetti masterpieces! 🎨",
    "players racing in confetti tracks! 🏎️",
    "explorers on a confetti quest! 🧭",
    "athletes in the confetti games! 🏅",
    "magicians conjuring confetti spells! 🪄",
    "strategists planning confetti victories! 🧠",
    "friends uniting for confetti fun! 🤗"
];

export const emptyRoomMessages = [
    "🎈 This room is waiting for its first confetti warrior!",
    "🚪 It's quiet... too quiet. Be the first to enter!",
    "🎉 No players yet – why not start the party?",
    "⚡ Ready to make some confetti? Jump in!",
    "🎊 The room is empty, but the confetti cannon is ready for you!",
    "👀 Looks like you could be the first confetti commander!",
    "🎮 No players yet, but the confetti’s ready to fly!",
    "✨ Be the trailblazer – start the confetti chaos!",
    "🤫 It’s a little quiet... someone needs to pop the first confetti!",
    "🎯 Be the first to ignite the confetti frenzy!",
    "🎆 A fresh battlefield awaits its first confetti hero!",
    "🚀 The launch pad is ready. Be the first to take off!",
    "🌟 No stars in this sky yet. Shine the brightest by joining now!",
    "🎢 The confetti rollercoaster is empty. Hop on for the first ride!",
    "🔥 The confetti cannons are loaded but unmanned. Ready to fire?",
    "🍭 The sweet victory is unclaimed. Take the first bite!",
    "🏰 An empty arena awaits its champion!",
    "🛸 Beam into the fun! You're the first one here.",
    "🌈 No colors flying yet. Start the confetti rainbow!",
    "🕹️ No one has pressed start. Be the first to play!",
    "📣 Silence in the arena... Your confetti awaits!",
    "⏳ The countdown is over, but no one's here. Jump in!",
    "🌊 The confetti wave is still. Make the first splash!",
    "⚽ The field is empty. Kick off the confetti game!",
    "🎺 The confetti parade needs a leader. Step up!",
    "🚦 All lights are green. You can be the first to go!",
    "🔮 The confetti future is unwritten. Start creating it!",
    "🎻 The stage is set but the orchestra is missing. Lead the symphony!",
    "🗺️ Uncharted confetti territory ahead. Be the explorer!",
    "🍀 Luck favors the bold. Enter the empty room!",
    "🌌 The confetti cosmos is vast and empty. Fill it with your stars!",
    "🎨 Blank canvas alert! Paint it with confetti colors!",
    "🏝️ Deserted confetti island. Claim it as yours!",
    "🌞 The confetti sun is rising. Be the first to feel its warmth!",
    "📚 The confetti story begins with you. Start the first chapter!"
];

export const activeGameMessages = [
    "🎯 Game in progress – confetti flying everywhere!",
    "⚔️ Confetti battle in progress, join the frenzy!",
    "💥 Confetti is flying – don’t miss out!",
    "🌀 The confetti storm is raging – jump in!",
    "🔥 Confetti combat in full swing!",
];

// Function to get a random message from an array
export function getRandomMessage(messagesArray, input) {
    let hash = 0;
    for (let i = 0; i < input.length; i++) {
        hash = input.charCodeAt(i) + ((hash << 5) - hash);
    }
    const index = Math.abs(hash) % messagesArray.length;
    console.log("index", index);
    return messagesArray[index];
}
