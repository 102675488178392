import React from 'react';
import { Box, Typography } from '@mui/material';

const TermsOfUse = () => {
    return (
        <Box sx={{ padding: 4 }}>
            <Typography variant="h3" gutterBottom>
                Terms of Use
            </Typography>
            <Typography paragraph>
                Last Updated: October 17th 2024.
            </Typography>
            <Typography variant="h6" gutterBottom>
                1. Acceptance of Terms
            </Typography>
            <Typography paragraph>
                By accessing and using Confetti Combat (“the Game”), you agree to these Terms of Use, including any updates or changes. If you do not agree to these terms, you should not access or play the Game.
            </Typography>
            <Typography variant="h6" gutterBottom>
                2. User Conduct
            </Typography>
            <Typography paragraph>
                You agree to use the Game in a manner consistent with all applicable laws and regulations. You must not:

                •	Use the Game for any unlawful purposes.
                •	Exploit any bugs or glitches for unfair advantages.
                •	Harass, abuse, or harm other players.
            </Typography>

            <Typography variant="h6" gutterBottom>
                3.  Modifications to the Game
            </Typography>
            <Typography paragraph>
                We reserve the right to modify, suspend, or discontinue the Game at any time with or without notice.
            </Typography>
            <Typography variant="h6" gutterBottom>
                4.  Disclaimers and Limitation of Liability
            </Typography>
            <Typography paragraph>
                The Game is provided “as is” and without any warranties of any kind. We are not liable for any damages arising from the use or inability to use the Game.
            </Typography>
            <Typography variant="h6" gutterBottom>
                5.   Changes to the Terms
            </Typography>
            <Typography paragraph>
                We may update these Terms of Use at any time. Your continued use of the Game signifies your acceptance of the updated terms.
            </Typography>
            <Typography variant="h6" gutterBottom>
                6.   Termination
            </Typography>
            <Typography paragraph>
                We reserve the right to terminate or suspend your access to the Game at our discretion, without notice, if you violate any of these Terms of Use.
            </Typography>
        </Box>
    );
};

export default TermsOfUse;