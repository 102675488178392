import { useState, useEffect } from 'react'
import {
  Box,
  Card,
  Button,
  Typography,
  TextField,
  Grid2,
  CardHeader,
  CardContent,
  CardActions
} from '@mui/material'

import { confetti } from '@tsparticles/confetti';
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { updateUsername } from '../../redux/gameSlice'
import CelebrationIcon from '@mui/icons-material/Celebration';
import logo from '../../assets/logo.png';
import { Link } from 'react-router-dom';

import useStyles from './styles.js'

export default () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { username: currentUsername } = useSelector(state => state.game)
  const [username, setUsername] = useState(currentUsername)
  const [pin, setPin] = useState('')

  useEffect(() => {
    let timeoutId;

    const triggerConfetti = () => {
      confetti({
        particleCount: 100,
        spread: 360,
        origin: { x: Math.random(), y: Math.random() },
      });

      timeoutId = setTimeout(triggerConfetti, Math.random() * 1000);
    };

    triggerConfetti()

    return () => {
      clearTimeout(timeoutId);
    }
  }, [])

  const handleKeyDown = (event) => {
    if (event.key != 'Enter' || !username.length || !pin.length) return

    onPlay()
  }

  const rainConfetti = () => {
    const promises = [];

    for (let i = 0; i < 25; i++) {
      const promise = confetti({
        particleCount: 100,
        spread: 360,
        origin: { x: Math.random(), y: Math.random() },
      });

      promises.push(promise);
    }
    Promise.all(promises)
  }

  const onPlay = () => {
    rainConfetti();
    dispatch(updateUsername(username))
    navigate('/rooms')
  }

  return (
    <Grid2 xs={12} container className={classes.LoginPageContainer} >
      <Card xs={12} variant="raised" className={'classes.LoginCard glowing-dialog'} sx={{ width: "30%", zIndex: 10 }} >
        <CardContent>
          <Grid2 xs={12} container direction="column" rowSpacing={1} alignItems="center" >
            <Grid2 xs={12} padding={5} >
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                <img src={logo} alt="Confetti Combat Logo" style={{ maxWidth: '200px', display: 'block', margin: '0 auto' }} />
              </Box>
            </Grid2>
            <Grid2 xs={12} padding={5} >
              <Typography variant="h5" sx={{ maxWidth: '400px', width: '100%', textAlign: 'center' }}>
                Enter a username and pin to store your high scores in Confetti Combat. The pin? Just numbers. No top-secret missions, just pure confetti fun! No email required!
              </Typography>
            </Grid2>
            <Grid2 container alignItems="center" direction="column" xs={12} rowSpacing={3} >
              <Grid2 xs={12} padding={1} >
                <TextField
                  autoFocus
                  fullWidth
                  label='Username'
                  value={username}
                  onChange={({ target: { value } }) => setUsername(value)}
                  sx={{ minWidth: '300px', width: '100%' }}
                  onKeyDown={handleKeyDown}
                />
              </Grid2>
              <Grid2 xs={12}>
                <TextField
                  fullWidth
                  label='Pin'
                  placeholder='6+ Numbers'
                  value={pin}
                  onChange={({ target: { value } }) => setPin(value)}
                  sx={{ minWidth: '300px', width: '100%' }}
                  type="number"  // Restrict input to numeric values
                  inputProps={{
                    min: 0, // Prevent negative numbers
                    pattern: "[0-9]*", // Ensures only numeric input
                  }}
                  onKeyDown={handleKeyDown}
                />
                <Box sx={{ textAlign: 'center', marginTop: "15px" }}>
                  <Typography variant="body1">
                    By clicking ‘Play’, you agree to our <Link to="/terms-of-use">Terms of Use</Link>
                  </Typography>
                </Box>
              </Grid2>
            </Grid2>
          </Grid2>
        </CardContent>
        <CardActions className={classes.LoginCardActions}>
          <Grid2 container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: "100%", paddingBottom: "4em" }}>
            <Button
              variant="outlined"
              onClick={onPlay}
              endIcon={<CelebrationIcon />}
              disabled={username.length <= 3 || pin.length < 6}
              size="large"
            >
              Play
            </Button>
          </Grid2>
        </CardActions>
      </Card>
    </Grid2 >
  )
}
