

export const getNormalizedMousePosition = (event) => {
    const mouseX = event.clientX;
    const mouseY = event.clientY;

    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;

    const normalizedX = mouseX / windowWidth;
    const normalizedY = mouseY / windowHeight;

    return { normalizedX, normalizedY };
};

