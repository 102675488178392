import { useState, useEffect, useRef, useCallback } from 'react';
import { gameStart, gameEnd, gameUserJoined, gameUserLeft, gameTapUpdate, mouseMovementUpdate, chatMessageReceived } from '../redux/gameSlice';
import { useDispatch } from 'react-redux';

const PING_INTERVAL = 15000
const INITIAL_RETRY_DELAY = 1000

const useWebSocket = (subscriberCallback, username, roomName, subscribedMessageTypes) => {
  const dispatch = useDispatch()
  const [connectionStatus, setConnectionStatus] = useState('disconnected')
  const wsRef = useRef(null)
  const pingTimeoutRef = useRef(null)
  const retryTimeoutRef = useRef(null)
  const retryDelayRef = useRef(INITIAL_RETRY_DELAY)
  const isConnectingRef = useRef(false)

  const sendPing = useCallback(() => {
    if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
      wsRef.current.send(JSON.stringify({ type: 'PING' }))

      pingTimeoutRef.current = setTimeout(sendPing, PING_INTERVAL)
    }
  }, [])

  const handleMessage = useCallback((event) => {
    const message = JSON.parse(event.data)

    if (pingTimeoutRef.current) {
      clearTimeout(pingTimeoutRef.current)
    }

    pingTimeoutRef.current = setTimeout(sendPing, PING_INTERVAL)

    routeMessage(message, subscriberCallback, subscribedMessageTypes, dispatch)
  }, [dispatch, subscriberCallback, subscribedMessageTypes, sendPing])

  const sendMessage = useCallback((type, payload = {}) => {
    if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
      const message = JSON.stringify({
        type,
        ...payload,
      });
      wsRef.current.send(message);
    }
  }, [])

  const connectWebSocket = useCallback(() => {
    if (!username || !roomName && !isConnectingRef.current) return

    isConnectingRef.current = true

    const wsUrl = `wss://${process.env.REACT_APP_API_URL}?roomName=${roomName}&userName=${username}`;
    const ws = new WebSocket(wsUrl);
    wsRef.current = ws;

    setConnectionStatus('connecting');
    console.log(`Attempting to connect to WebSocket: ${wsUrl}`);

    ws.onopen = () => {
      setConnectionStatus('connected');
      console.log(`WebSocket connected to room: ${roomName} as user: ${username}`);

      retryDelayRef.current = 1000
      isConnectingRef.current = false

      if (retryTimeoutRef.current) {
        clearTimeout(retryTimeoutRef.current)
      }

      pingTimeoutRef.current = setTimeout(sendPing, PING_INTERVAL)
    }

    ws.onmessage = handleMessage

    ws.onclose = () => {
      setConnectionStatus('disconnected')
      console.log('WebSocket connection closed')

      isConnectingRef.current = false

      if (pingTimeoutRef.current) {
        clearTimeout(pingTimeoutRef.current)
      }

      // Attempt to reconnect with exponential backoff
      if (!isConnectingRef.current) {
        console.log(`Retrying WebSocket connection in ${retryDelayRef.current / 1000} seconds...`)

        retryTimeoutRef.current = setTimeout(() => {
          connectWebSocket()

          retryDelayRef.current = Math.min(retryDelayRef.current * 2, 60000) // Exponential backoff, max 60 seconds
        }, retryDelayRef.current)
      }
    };

    ws.onerror = (error) => {
      setConnectionStatus('error')
      console.error('WebSocket error:', error)
    }
  }, [username, roomName])

  useEffect(() => {
    connectWebSocket()

    // Cleanup WebSocket connection when the component unmounts
    return () => {
      if (wsRef.current) {
        wsRef.current.close()
        wsRef.current = null
      }

      // Clear the PING timeout on cleanup
      if (pingTimeoutRef.current) {
        clearTimeout(pingTimeoutRef.current)
      }

      // Clear the retry timeout on cleanup
      if (retryTimeoutRef.current) {
        clearTimeout(retryTimeoutRef.current)
      }
    }
  }, [username, roomName, connectWebSocket])

  return { connectionStatus, sendMessage }
}

const routeMessage = (message, subscriberCallback, subscribedMessageTypes, dispatch) => {
  if (subscribedMessageTypes.includes(message.type)) subscriberCallback(message)

  if (message.type === 'TAP_UPDATE') return dispatch(gameTapUpdate(message))
  if (message.type === 'MOUSE_MOVED') return dispatch(mouseMovementUpdate(message))
  if (message.type === 'GAME_START') return dispatch(gameStart(message))
  if (message.type === 'GAME_END') return dispatch(gameEnd(message))
  if (message.type === 'USER_JOINED') return dispatch(gameUserJoined(message))
  if (message.type === 'USER_LEFT') return dispatch(gameUserLeft(message))
  if (message.type === 'CHAT_MESSAGE') return dispatch(chatMessageReceived(message));
}

export default useWebSocket
