import { React, useState } from 'react';
import { AppBar, Toolbar, Box, Typography, useTheme, Divider } from '@mui/material';
import logo from '../assets/logo.png'; // Replace with your logo path
import { useSelector } from 'react-redux'; // Assuming username is stored in Redux
import { getEmojiFromString } from '../redux/utils'
const Navbar = () => {
    const theme = useTheme();
    const username = useSelector((state) => state.game.username); // Fetch the username from the Redux state
    const primaryMainColor = theme.palette.primary;
    const [pageName] = useState('Rooms');

    return (
        <AppBar class="glowing" position="sticky" sx={{ maxHeight: "90px", backgroundColor: primaryMainColor }} color="secondary">
            <Toolbar color="secondary" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', ml: "10%", mr: "10%" }}>

                <Box sx={{ display: 'flex', justifyContent: 'flex-start', width: '33%' }}>
                    {(
                        <Typography variant="h3" >
                            {pageName}
                        </Typography>
                    )}

                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: "33%" }}>
                    <img src={logo} alt="Logo" style={{ maxHeight: '75px' }} />
                </Box>

                <Box p={1} sx={{ display: 'flex', justifyContent: 'flex-end', textAlign: "center", width: '33%', }}>
                    {username && (
                        <Box sx={{ justifyContent: 'center', textAlign: "center", }}>
                            <Typography variant="h1" sx={{ fontSize: "3em", m: 0 }} >
                                {getEmojiFromString(username)}
                            </Typography>
                            <Typography variant="h5" >
                                {username}
                            </Typography>

                        </Box>
                    )}
                </Box>
            </Toolbar>
        </AppBar>
    );
};

export default Navbar;