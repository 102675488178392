import { createStyles, makeStyles } from '@mui/styles';

export default makeStyles(theme => createStyles({
  LoginPageContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    borderRadius: "2em",
    boxShadow: `0px 4px 10px ${theme.palette.secondary.main}`,
  },
  LoginCard: {
    maxWidth: '800px',
    zIndex: 1000,
    boxShadow: `10px -1px 100px ${theme.palette.secondary.main}`,

  },
  LoginCardActions: {
    height: '5em',
    marginTop: '20px',
  }
}));
