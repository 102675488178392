import { createStyles, makeStyles } from '@mui/styles';

export default makeStyles(theme => createStyles({
    SideContainer: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        zIndex: 999,
        padding: "2em",
        borderRadius: '25px'
    },
}));
