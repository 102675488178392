import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateRoomSettings } from '../services/room.service';
import {
    Popover,
    DialogTitle,
    DialogContent,
    Divider,
    TextField,
    Button,
    IconButton,
    Box,
    Typography,
    FormControl,
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close'
import { Slider, Sketch, Material, Colorful, Compact, Circle, Wheel, Block, Github, Chrome } from '@uiw/react-color';
import { Alpha, Hue, ShadeSlider, Saturation, Interactive, hsvaToHslaString } from '@uiw/react-color';
import { EditableInput, EditableInputRGBA, EditableInputHSLA } from '@uiw/react-color';



import { roomSettingsUpdate } from '../redux/gameSlice';

const RoomSettings = ({ roomName, presetColor, presetBackgroundImage, textColor }) => {
    const dispatch = useDispatch();
    const [color, setColor] = useState(presetColor ?? '#FFFFFF');
    const [theme, setTheme] = useState('Color');
    const [backgroundImage, setBackgroundImage] = useState(presetBackgroundImage ?? '');
    const [anchorEl, setAnchorEl] = useState(null);

    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => setAnchorEl(null);

    const open = Boolean(anchorEl);

    const handleSave = async () => {
        try {
            await updateRoomSettings(roomName, color, theme, backgroundImage);
            dispatch(roomSettingsUpdate({ backgroundColor: color, theme, backgroundImage }));
            handleClose();
        } catch (error) {
            console.error('Failed to update room settings:', error);
        }
    };

    function isValidImageUrl(url) {
        return /\.(jpg|jpeg|png|gif|bmp|webp|svg)$/i.test(url);
    }

    return (
        <>
            {/* Icon to Open Popover */}
            <IconButton onClick={handleOpen}>
                <Box sx={{ zIndex: 999999 }}>
                    <Typography color={textColor} >
                        <SettingsIcon />
                    </Typography>
                </Box>
            </IconButton>

            {/* Popover Box */}
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'top',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'top',
                }}
                PaperProps={{
                    sx: {
                        background: 'rgba(255, 255, 255, 0.94)',
                        color: `${color}`,
                        padding: 0,
                        borderRadius: 2,
                        width: '40em',
                        textAlign: 'center'
                    },
                }}
            >
                <DialogTitle sx={{ p: 0, m: 0 }}>
                    <Box sx={{ position: 'relative', display: 'inline-block', width: 'fit-content', justifyContent: 'center' }}>
                        <Typography
                            variant='h3'
                            sx={{
                                zIndex: 1,
                                p: 1,
                                color: 'grey'
                            }}
                        >Settings</Typography>
                    </Box>
                </DialogTitle>

                <DialogContent sx={{ p: 0, m: 0 }}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            width: '100%',
                            gap: 2,
                            p: 1
                        }}
                    >
                        <Typography variant='h4' color='grey'>Color</Typography>
                        <Box margin={'auto'} gap="2">
                            <Box>
                                <Sketch
                                    style={{ width: '100%', marginBottom: '1em' }}
                                    color={color}
                                    onChange={(color) => {
                                        setColor(color.hex);
                                    }}
                                />
                                <Typography sx={{ color: 'grey', p: 2 }} variant='body'>
                                    Get your color vibes on! Choose a color for your room, affecting both text and background. Want a clean, solid color look? Just leave the image field empty, and let your color shine!
                                </Typography>
                            </Box>
                        </Box>
                        <Typography variant='h4' sx={{ color: 'grey' }}>Background Image</Typography>

                        <FormControl fullWidth >

                            <TextField
                                label='Image Url'
                                type='text'
                                fullWidth
                                variant='outlined'
                                placeholder='Image Url'
                                textColor="grey"
                                value={backgroundImage}
                                onChange={(event) => setBackgroundImage(event.target.value)}
                                sx={{ marginTop: '16px' }}
                                InputProps={{
                                    sx: {
                                        border: '1px solid grey',
                                        color: 'black', // Set the text color to black
                                    },
                                }}
                                InputLabelProps={{
                                    sx: {
                                        color: 'grey', // Set the label color to black as well, if needed
                                    }
                                }}
                            />

                            <Typography sx={{ color: 'grey', p: 2 }} variant='body'>Make your play area pop! Drop in a URL to any image, and it'll become the background for the game. Just a heads-up: it's public and everyone can see your creative flair! </Typography>

                        </FormControl >

                        {isValidImageUrl(backgroundImage) && (
                            <Box>
                                <Box textAlign={'center'} p={2}>
                                    <Typography variant='h4' sx={{ color: 'grey' }}>Image Preview</Typography>
                                </Box>
                                <img src={backgroundImage} width={400} alt='Background' />
                            </Box>
                        )
                        }
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: 2,
                            mb: 2
                        }}>
                            <Button onClick={handleClose} s variant='outlined' sx={{ color: 'grey' }} >
                                <CloseIcon />
                            </Button>
                            <Button onClick={handleSave} variant='outlined' sx={{ color: 'grey' }} >
                                <SaveIcon />
                            </Button>

                        </Box>
                    </Box >
                </DialogContent >
            </Popover >
        </  >
    );
};

export default RoomSettings;