import { Grid } from '@mui/material'
import useStyles from './styles'
import { Outlet } from 'react-router-dom'
import Navbar from '../../components/Navbar'

export default () => {
  const classes = useStyles()

  return (
    <Grid container className={classes.AppLayoutContainer}>
      <Outlet />
    </Grid>
  )
}
