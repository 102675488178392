import React, { useState, useEffect, useRef } from 'react';
import { Typography, Pagination, Box } from '@mui/material';

function stringToHexColor(input) {
    let hash = 0;
    for (let i = 0; i < input.length; i++) {
        hash = input.charCodeAt(i) + ((hash << 5) - hash);
    }
    const color = `#${((hash >> 0) & 0xffffff).toString(16).padStart(6, '0')}`;
    return color;
}

const PlayerList = ({ players, isGameRunning, textColor, playersPerPage = 4 }) => {
    const [page, setPage] = useState(1);

    const totalPages = Math.ceil(players.length / playersPerPage);
    const indexOfLastPlayer = page * playersPerPage;
    const indexOfFirstPlayer = indexOfLastPlayer - playersPerPage;
    const currentPlayers = players.slice(indexOfFirstPlayer, indexOfLastPlayer);
    const carouselRef = useRef(null)

    let debounceTimer;

    const handleChange = (event, value) => {
        setPage(value);
        clearTimeout(debounceTimer);
    };



    return (
        <Box mb="1em">
            <Typography variant="h5" mt={1} mb={1} sx={{ color: textColor }}>
                Players: {players.length}
            </Typography>

            <Box
                display="flex"
                flexWrap="wrap"
                justifyContent="space-evenly"
            >
                {currentPlayers.map((player, index) => (
                    <Box
                        key={index}
                        p={0.5}
                    >
                        <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="space-evenly"
                            p={0}
                        >
                            {/* Emoji */}
                            <Typography variant="h1" textAlign="center">
                                {player.userEmoji}
                            </Typography>

                            {/* Score */}
                            {isGameRunning && (
                                <Typography variant="h2" textAlign="center" color={stringToHexColor(player.username)}>
                                    {player.userScore}
                                </Typography>
                            )}

                            {/* Username */}
                            <Typography
                                variant="subtitle1"
                                textAlign="center"
                                sx={{
                                    wordWrap: 'break-word',
                                    overflowWrap: 'break-word',
                                }}
                                color={stringToHexColor(player.username)}
                            >
                                {player.username}
                            </Typography>
                        </Box>
                    </Box>
                ))}
            </Box>

            {
                totalPages > 1 && (
                    <Box mt={2} display="flex" justifyContent="center">
                        <Pagination
                            count={totalPages}
                            page={page}
                            onChange={handleChange}
                            sx={{
                                color: { textColor },
                                ul: {
                                    '& .MuiPaginationItem-page': {
                                        display: 'none',
                                    },
                                    '& .MuiPaginationItem-icon': {
                                        border: `2px solid white `,
                                        borderRadius: '0.1em',
                                        padding: 0,
                                        fontSize: '0.75em',
                                    },
                                    '& .MuiPaginationItem-root': {
                                        fontSize: '4em',
                                    },
                                },
                            }}
                        />
                    </Box>
                )
            }
        </Box >
    );
};

export default PlayerList;