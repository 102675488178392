import { Button, Divider, Grid, Typography, Box, Paper } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { getCurrentRoomInfo, gameUserJoined, gameTapUpdate, mouseMovementUpdate, getChats } from '../../redux/gameSlice'
import useWebSocket from '../../hooks/useWebSocket'
import { startGame as startGameAPI } from '../../services/room.service'
import { confetti } from '@tsparticles/confetti'
import MatchResultsModal from '../../components/MatchResultsDialog'
import Countdown from '../../components/Countdown'
import Chat from '../../components/Chat'
import Bomb from '../../components/Bomb'
import { getNormalizedMousePosition } from './utils'
import CelebrationIcon from '@mui/icons-material/Celebration'
import UserEmojis from '../../components/UserEmojis'
import RoomSettings from '../../components/RoomSettings'
import useStyles from './styles.js'
import PlayerList from '../../components/PlayerList'
import chroma from "chroma-js";
import 'animate.css';
import logo from '../../assets/logo.png';
import PhaserGame from '../../components/PhaserGame/PhaserGame.js'

export default () => {
  const dispatch = useDispatch()
  const classes = useStyles();
  const { loading, currentRoom, username, currentGame, userLocations, userEmojis, backgroundColor, theme, backgroundImage } = useSelector(state => state.game)
  const { roomName } = useParams()
  const [isGameRunning, setIsGameRunning] = useState(false)
  const [bombs, setBombs] = useState([])
  const updateUserMouseTimeoutRef = useRef(null)
  const currentPositionRef = useRef(null)
  const animationRef = useRef(null)
  const [init, setInit] = useState(false);
  const [tapMultiplier, setTapMultiplier] = useState(1)
  const [isFrozen, setIsFrozen] = useState(false)
  const [isMoving, setIsMoving] = useState(false)
  const [lastPushedPosition, setLastPushedPosition] = useState('')
  let debounceTimer;
  const sideBarTextColor = chroma(backgroundColor).darken(2).hex();



  const { sendMessage } = useWebSocket((message) => {
    if (message.type === "TAP_UPDATE") {
      const confettiConfig = message.confettiConfig;
      message.taps.forEach(({ userName, coordinates: { x, y } }) => {
        confetti({ ...confettiConfig, origin: { x, y } })
      });
    }

    if (message.type === 'BOARD_EVENT') {
      setBombs(prevBombs => prevBombs.filter(({ gameObjectId }) => gameObjectId != message.gameObjectId))
    }
  }, username, roomName, ['TAP_UPDATE', 'BOARD_EVENT'])

  useEffect(() => {
    if (!currentRoom.active) setIsGameRunning(false)
  }, [currentRoom.active])

  useEffect(() => {
    dispatch(getCurrentRoomInfo(roomName))
    dispatch(getChats(roomName))
    dispatch(gameUserJoined({ userName: username }))
  }, [roomName]);

  const dropBomb = (bomb) => setBombs(prevBombs => [
    ...prevBombs,
    {
      ...bomb,
      top: 0,
      startTime: performance.now(),
      fallDuration: bomb.fallRate * 2000
    }
  ])

  const updateBombs = (time) => {
    setBombs(prevBombs => prevBombs
      .map(bomb => {
        const elapsedTime = time - bomb.startTime
        const progress = Math.min(elapsedTime / (bomb.fallDuration * 2), 1)
        const newTop = window.innerHeight * progress

        if (progress >= 1) return null // Bomb reached the bottom, remove it

        return {
          ...bomb,
          top: newTop,
        }
      })
      .filter(bomb => bomb !== null)
    )

    animationRef.current = requestAnimationFrame(updateBombs)
  }

  // Giving up on in game bombs.
  // useEffect(() => {
  //   if (isGameRunning) {
  //     currentGame.gamePlan.gameObjects.forEach(({ msAfterGameStart, ...bomb }) => setTimeout(
  //       () => dropBomb(bomb),
  //       msAfterGameStart
  //     ))

  //     animationRef.current = requestAnimationFrame(updateBombs) // Start updating bombs
  //   } else {
  //     setBombs([])
  //   }

  //   // Clean up the animation frame on component unmount or game end
  //   return () => {
  //     cancelAnimationFrame(animationRef.current)
  //   };
  // }, [isGameRunning])



  const handleMouseMovement = (event) => {
    const { normalizedX, normalizedY } = getNormalizedMousePosition(event);
    const position = { x: normalizedX, y: normalizedY };

    if (updateUserMouseTimeoutRef.current) return;

    currentPositionRef.current = position; // Update the ref instead of state
    dispatch(mouseMovementUpdate({ userName: username, coordinate: position }));

    updateUserMouseTimeoutRef.current = setTimeout(() => {
      updateUserMouseTimeoutRef.current = null;
    }, 10);
  };
  useEffect(() => {
    const intervalId = setInterval(() => {

      if (currentPositionRef.current?.x !== lastPushedPosition?.x || currentPositionRef.current?.y !== lastPushedPosition?.y) {
        sendMessage('MOUSE_MOVED', {
          coordinate: currentPositionRef.current,
          userName: username,
        });
        setLastPushedPosition({ ...currentPositionRef.current });
      }
    }, 100);

    return () => {
      clearInterval(intervalId);
    };
  }, [lastPushedPosition]);


  useEffect(() => {
    window.addEventListener('mousemove', handleMouseMovement)

    return () => {
      window.removeEventListener('mousemove', handleMouseMovement)
    }
  }, [])

  const handleTap = (event, tapDelta = 1) => {
    if (isFrozen) return

    const confettiConfig = getConfettiConfig(currentGame, username)
    const coordinates = userLocations[username];
    confetti({
      ...confettiConfig,
      origin: coordinates,
    })


    if (!isGameRunning) {
      return;
    }
    dispatch(gameTapUpdate({ taps: [{ userName: username, tapDelta: tapDelta * tapMultiplier }] }))

    sendMessage('TAP', {
      coordinates,
      tapDelta: tapDelta * tapMultiplier,
      confettiConfig: confettiConfig,
    })
  }

  const handleBombClick = (bomb) => (event) => {
    if (isFrozen) return

    handleTap(event, bomb.tapDelta)

    if (bomb.type == 'bomb') {
      setTapMultiplier(1)
    } else {
      setTapMultiplier(tapMultiplier * bomb.tapMultiplier)
    }

    if (bomb.type == 'freeze') {
      setIsFrozen(true)
      setTimeout(() => setIsFrozen(false), 3000)
    }

    setBombs(prevBombs => prevBombs.filter(({ gameObjectId }) => gameObjectId != bomb.gameObjectId))

    sendMessage('BOARD_EVENT', { gameObjectId: bomb.gameObjectId })
  }

  const startGame = async () => {
    try {
      await startGameAPI(roomName)
    } catch (error) {
      console.error('Failed to start the game:', error)
    }
  }

  if (loading || Object.keys(currentRoom).length === 0) return 'Loading...'


  const players = currentRoom.userNames
    .map(name => ({
      username: name,
      userEmoji: userEmojis[name],
      userScore: currentGame.score[name] || 0,
    }))
    .sort((a, b) => b.userScore - a.userScore);

  return (
    <>
      {/* {init && sparkleEnabled &&
        <Particles
          options={sparkleOption(backgroundColor)}
          id="themeContainer"
        />
      } */}
      <Grid
        container
        sx={{
          height: '100vh',
          position: 'relative',
          overflow: 'hidden',
          ...(theme === "Color" && { backgroundColor }),
        }}
      >
        <Grid item xs={3} sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          background: "linear-gradient(120deg, rgba(255, 255, 255, 0.5), rgba(255, 255,255, 0.1))",
          boxShadow: `1px 0 2px ${sideBarTextColor}`
        }}>
          <Box container direction="column" spacing={1} className={classes.SideContainer}>
            <Box container direction="column" spacing={1}>
              <Box direction="column" container sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', }}>
                <Box container direction="column">
                  <Typography variant="h1" color={sideBarTextColor}>
                    {currentRoom.roomName}
                    <RoomSettings presetColor={backgroundColor} presetBackgroundImage={backgroundImage} roomName={currentRoom.roomName} textColor={sideBarTextColor} />
                  </Typography>
                  <Typography variant="h6" display="block" color={sideBarTextColor} >
                    By {currentRoom.ownerName}
                  </Typography>
                </Box>
                <Box p={1} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                  <img src={logo} alt="Confetti Combat Logo" style={{ maxWidth: '90px', display: 'block', margin: '0 auto' }} />
                </Box>
              </Box>
              <Divider sx={{ mb: 2 }} />
              <Box item>
                <PlayerList players={players} isGameRunning={isGameRunning} textColor={sideBarTextColor} />
              </Box>
              <Divider sx={{ mb: 2 }} />
            </Box>
            <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>
              <Chat roomName={roomName} username={username} textColor={sideBarTextColor} />
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={9}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '8px',
            p: 2,
            height: '100%',
            position: 'relative',
            cursor: "none",
            ...(backgroundImage?.length && { backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' })
          }}
          onClick={handleTap}
        >
          <Typography variant="h4" color="textSecondary" sx={{ userSelect: 'none' }}>
            {isGameRunning
              ? <Typography class="animate__animated animate__flipOutX">More Tap = More Confetti</Typography>
              : currentRoom.active
                ? <Countdown
                  prefix='Game starting in '
                  suffix='...'
                  onCountdownFinish={() => setIsGameRunning(true)}
                  countUntil={currentGame.startTime}
                />
                : <Box mt="auto" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', background: "linear-gradient(90deg, rgba(255, 255, 255, 0.7), rgba(255, 255,255, 0.5))", borderRadius: 2, padding: 3, boxShadow: `4px 4px 4px ${sideBarTextColor}` }}>
                  <Typography variant="h1" sx={{ textAlign: 'center', marginBottom: "20px;", color: `${sideBarTextColor}` }}>
                    Your confetti's a secret until the game kicks off! 😉
                  </Typography>
                  <Typography variant="h3" sx={{ textAlign: 'center', marginBottom: "20px;", color: `${sideBarTextColor}` }}>
                    Confetti Combat is more fun with friends! 🎉
                  </Typography>
                  <Typography variant="h3" sx={{ textAlign: 'center', marginBottom: "20px;", color: `${sideBarTextColor}` }}>
                    Click to pick up power ups 🥳 ☂and weapons. Press X to fire.
                  </Typography>
                  <Button
                    onClick={startGame}
                    variant="outlined"
                    size="large"
                    sx={{
                      maxWidth: '200px', width: '100%', color: `${sideBarTextColor}`, border: `2px solid ${sideBarTextColor}`, zIndex: 9999
                    }}
                    endIcon={<CelebrationIcon />}
                  >
                    Start Game
                  </Button>
                </Box>
            }
          </Typography>

          {!isGameRunning && <PhaserGame userLocations={userLocations} userEmojis={userEmojis} username={username} roomColor={sideBarTextColor} />}
        </Grid >
        <MatchResultsModal />
        {
          isGameRunning && (
            <div style={{ position: 'absolute', right: '20px' }}>
              <Typography variant="h2" color="textSecondary" sx={{ userSelect: 'none' }}>
                <Countdown countUntil={currentGame.startTime + currentGame.duration} />
              </Typography>
            </div>
          )
        }
        <img
          src="https://p.productioncrate.com/stock-hd/effects/footagecrate-frost-on-screen-1-prev-full.png"
          style={{
            position: 'absolute',
            marginLeft: '-10px',
            marginTop: '-10px',
            width: '100%',
            height: '100%',
            zIndex: 10000,
            opacity: isFrozen ? 1 : 0,
            transition: 'opacity 0.7s linear',
            pointerEvents: 'none'
          }}
        />

      </Grid >
    </>
  );

};
function getConfettiConfig(currentGame, username, message) {
  const confettiCount = currentGame?.score[username] ?? 0
  const defaultConfettiConfig = {
    particleCount: confettiCount > 0 ? confettiCount : 70,
    spread: 20,
  }

  return defaultConfettiConfig
};
