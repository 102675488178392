export default function createEmojiTexture(scene, emojiChar, textureKey, fontSize = '55px') {
    const emojiFont = `${fontSize} Arial`;

    const emojiText = scene.add.text(0, 0, emojiChar, {
        font: emojiFont,
        color: '#FFFFFF',
    }).setOrigin(0.5, 0.5);
    emojiText.setScale(1.0);
    emojiText.setResolution(window.devicePixelRatio);
    emojiText.visible = false;
    emojiText.updateText();

    const width = emojiText.width;
    const height = emojiText.height;

    const renderTexture = scene.add.renderTexture(0, 0, width, height);
    renderTexture.draw(emojiText, width / 2, height / 2);
    renderTexture.saveTexture(textureKey);

    emojiText.destroy();
    renderTexture.destroy();
}

