import { useState, useEffect } from "react"

export default ({
  prefix = '',
  suffix = '',
  onCountdownFinish = () => {},
  countUntil
}) => {
  const calculateTimeLeft = () => {
    return Math.floor((countUntil - new Date().getTime()) / 1000)
  }

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft())

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft(calculateTimeLeft())

      if (new Date().getTime() > countUntil) onCountdownFinish()
    }, 1000)

    return () => clearInterval(interval)
  }, [])


  return prefix + timeLeft + suffix
}
