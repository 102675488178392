import React, { useEffect, useRef } from 'react';
import Phaser, { Utils } from 'phaser';
import PowerUp from './PowerUp';
import gun from '../../assets/gun.png';
import createEmojiTexture from './utils';
import Gun from './Gun';

const PhaserGame = ({ userLocations, userEmojis, username, roomColor }) => {
    const gameContainerRef = useRef(null);
    const usernameRef = useRef(username);
    const phaserGameRef = useRef(null);
    const userLocationsRef = useRef(userLocations);
    const userEmojisRef = useRef(userEmojis);

    useEffect(() => {
        userLocationsRef.current = userLocations;
    }, [userLocations]);

    useEffect(() => {
        userEmojisRef.current = userEmojis;
    }, [userEmojis]);

    useEffect(() => {
        usernameRef.current = username;
    }, [username]);

    useEffect(() => {
        if (phaserGameRef.current) {
            return;
        }

        const container = gameContainerRef.current;
        const width = container.clientWidth || window.innerWidth;
        const height = container.clientHeight || window.innerHeight;

        class ConfettiCombatGame extends Phaser.Scene {
            constructor(userLocationsRef, userEmojisRef) {
                super({ key: 'MyGame' });
                this.userLocationsRef = userLocationsRef;
                this.userEmojisRef = userEmojisRef;
            }

            getScreenPosition(position) {
                const x = position.x * this.scale.width;
                const y = position.y * this.scale.height;
                const widthDifferential = width / 4;

                return {
                    x: x - widthDifferential, y
                };
            }


            preload() {
                this.load.image('gun', gun);
                this.buildTextures();
            }


            create() {
                this.setGroundColorHeightAndDepth();
                this.initializeUserEmojis();


                this.powerdowns = this.physics.add.group();
                this.powerups = this.physics.add.group();
                this.weapons = this.physics.add.group();

                this.setupGuns();
                this.addPowerUps();

                this.physics.add.collider(this.powerups, this.ground);
                this.physics.add.collider(this.powerdowns, this.ground);
                this.physics.add.collider(this.weapons, this.ground);
            }

            addPowerUps() {
                this.partyCircle1 = new PowerUp(this, this.scale.width / 4, 0);
                this.partyCircle2 = new PowerUp(this, this.scale.width / 8, 0, '☂️', false);
                this.partyCircle3 = new PowerUp(this, this.scale.width / 2, 0, '🎊', false);
                this.partyCircle4 = new PowerUp(this, this.scale.width / 12 + 100, 0, '🧊', false, true);
                this.partyCircle5 = new PowerUp(this, this.scale.width - 600, 0);
                this.powerups.add(this.partyCircle1);
                this.powerups.add(this.partyCircle2);
                this.powerups.add(this.partyCircle3);
                this.powerups.add(this.partyCircle4);
                this.powerups.add(this.partyCircle5);
            }

            // addPowerUp(x, powerUpText,) {
            //     this.partyCircle1 = new PowerUp(this, this.scale.width / 4, 0);
            //     this.partyCircle2 = new PowerUp(this, this.scale.width / 8, 0, '☂️', false);
            //     // this.partyCircle3 = new PowerUp(this, this.scale.width / 6, 0, '🧊', false, true);
            //     // this.partyCircle4 = new PowerUp(this, this.scale.width - 600, 0);
            //     // sprites.current.push(this.partyCircle1);
            //     // this.partyCircle2, this.partyCircle3, this.partyCircle4);
            // }

            initializeUserEmojis() {
                this.userEmojiTexts = {};

                // Create initial emoji texts
                Object.entries(this.userLocationsRef.current).forEach(([name, position]) => {
                    const { x, y } = this.getScreenPosition(position);
                    const emoji = this.userEmojisRef.current[name] || '😊';

                    const text = this.add.text(x, y, emoji, {
                        font: '55px Arial',
                        resolution: window.devicePixelRatio,
                    }).setOrigin(0.5, 0.5);

                    this.userEmojiTexts[name] = text;
                });
            }

            buildTextures() {
                createEmojiTexture(this, '🥳', 'power-uped');
                createEmojiTexture(this, '💧', 'water-drop', '45px');
                this.createEmojiTextureForScene(this, '⬤', 'square-red', '#FF0000');
                this.createEmojiTextureForScene(this, '⬤', 'square-orange', '#FF7F00');
                this.createEmojiTextureForScene(this, '⬤', 'square-yellow', '#FFFF00');
                this.createEmojiTextureForScene(this, '⬤', 'square-green', '#00FF00');
                this.createEmojiTextureForScene(this, '⬤', 'square-blue', '#0000FF');
                this.createEmojiTextureForScene(this, '⬤', 'square-indigo', '#4B0082');
                this.createEmojiTextureForScene(this, '⬤', 'square-violet', '#9400D3');
                this.createEmojiTextureForScene(this, '⬤', 'square-pink', '#FF1493');
                this.createEmojiTextureForScene(this, '⬤', 'square-cyan', '#00FFFF');
                this.createEmojiTextureForScene(this, '⬤', 'square-magenta', '#FF00FF');
                this.createEmojiTextureForScene(this, '⬤', 'square-lime', '#32CD32');
                this.createEmojiTextureForScene(this, '⬤', 'square-teal', '#008080');
                this.createEmojiTextureForScene(this, '⬤', 'square-black', '#000000', true);
            }

            setGroundColorHeightAndDepth() {
                const roomColorHex = roomColor?.replace('#', '0x');
                this.ground = this.add.rectangle(width / 2, height, width, 100, roomColorHex);
                this.physics.add.existing(this.ground, true); // true makes it static
                this.ground.setDepth(1000);
            }

            setupGuns() {
                const gun = new Gun(
                    this,
                    width * 0.5,
                    0,
                    username,
                    userLocationsRef
                );
                const gun2 = new Gun(
                    this,
                    width * 0.2,
                    0,
                    username,
                    userLocationsRef
                );
                const gun3 = new Gun(
                    this,
                    width * 0.8,
                    0,
                    username,
                    userLocationsRef
                );
                this.weapons.add(gun);
                this.weapons.add(gun2);
                this.weapons.add(gun3);
            }

            update() {
                this.updateEmojiPositions();
                if (!this.particles && !this.partyCircle1?.active && this.userEmojiTexts[username]) {

                    this.particles = this.add.particles(0, 0, 'poweruped', {
                        speed: 1000,
                        blendMode: 'ADD',
                        lifespan: 1000,
                        maxAliveParticles: 1000,
                        scale: { start: 0.01, end: 0.15 },
                        quantity: 4,
                    });
                    if (this.userEmojiTexts[username]) {
                        this.userEmojiTexts[username].setDepth(1);
                        this.particles.startFollow(this.userEmojiTexts[username], 0, 0, true);
                    }

                    if (this.particles) {
                        this.particles.depth = 1;
                    }
                }

                // Remove texts for users who have left
                Object.keys(this.userEmojiTexts).forEach((name) => {
                    if (!this.userLocationsRef.current[name] && this.userEmojiTexts[name]) {
                        this.userEmojiTexts[name].destroy();
                        delete this.userEmojiTexts[name];
                    }
                });
            }

            updateEmojiPositions() {
                Object.entries(this.userLocationsRef.current).forEach(([name, position]) => {
                    const text = this.userEmojiTexts[name];

                    const { x, y } = this.getScreenPosition(position);
                    const emoji = this.userEmojisRef.current[name] || '😊';

                    if (text) {
                        // Update position and emoji if necessary
                        text.setPosition(x, y);
                        if (text.text !== emoji) {
                            text.setText(emoji);
                        }
                    } else {
                        // New user, add the text
                        const newText = this.add.text(x, y, emoji, {
                            font: '55px Arial',
                            resolution: window.devicePixelRatio,
                        }).setOrigin(0.5, 0.5);

                        this.userEmojiTexts[name] = newText;
                    }
                });
            }

            createEmojiTextureForScene(scene, emojiChar, textureKey, color) {
                const emojiFont = '200px Arial';

                const emojiText = scene.add.text(0, 0, emojiChar, {
                    font: emojiFont,
                    color,
                }).setOrigin(0.5, 0.5);
                emojiText.setScale(0.5);
                emojiText.visible = false;

                const width = emojiText.width;
                const height = emojiText.height;

                const renderTexture = scene.add.renderTexture(0, 0, width, height);
                renderTexture.draw(emojiText, width / 2, height / 2);
                renderTexture.saveTexture(textureKey);
                emojiText.destroy();
                renderTexture.destroy();
            }
        }


        const confettiCombat = new ConfettiCombatGame(userLocationsRef, userEmojisRef);


        const config = {
            type: Phaser.AUTO,
            parent: gameContainerRef.current,
            width: width,
            height: height,
            physics: {
                default: 'arcade',
                arcade: {
                    gravity: { y: 1000 },
                    debug: false,
                },
            },
            scene: confettiCombat,
            transparent: true,
            scale: {
                mode: Phaser.Scale.AUTO,
            },
        };

        phaserGameRef.current = new Phaser.Game(config);

        return () => {
            if (phaserGameRef.current) {
                phaserGameRef.current.destroy(true);
                phaserGameRef.current = null;
            }
        };
    }, []);

    return (
        <div
            ref={gameContainerRef}
            style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: `${window.innerWidth}px`,
                height: `${window.innerHeight}px`,
                overflow: 'hidden',
            }}
        />
    );
};

export default PhaserGame;
