const BASE_URL = `https://${process.env.REACT_APP_API_URL}`

export const getChatMessages = async (roomName, skip = 0, limit = 50) => {
  try {
    const response = await fetch(`${BASE_URL}/rooms/${roomName}/messages?skip=${skip}&limit=${limit}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    return response.json();
  } catch (error) {
    console.error('Failed to fetch messages:', error);
    throw error;
  }
};
