import { createTheme } from '@mui/material/styles';

const draculaTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#bd93f9', // Dracula Purple
    },
    secondary: {
      main: '#ff79c6', // Dracula Pink
    },
    background: {
      default: '#282a36', // Dracula Background
      paper: '#44475a', // Dracula Paper Background
    },
    text: {
      primary: '#f8f8f2', // Dracula Foreground
      secondary: '#6272a4', // Dracula Comments/Secondary Text
    },
    error: {
      main: '#ff5555', // Dracula Red
    },
    warning: {
      main: '#f1fa8c', // Dracula Yellow
    },
    info: {
      main: '#8be9fd', // Dracula Cyan
    },
    success: {
      main: '#50fa7b', // Dracula Green
    },
  },
  typography: {
    fontFamily: '"Roboto", monospace', // Global font
    h1: {
      fontSize: '2rem', // 32px
      fontWeight: 700,
    },
    h2: {
      fontSize: '1.75rem', // 28px
      fontWeight: 600,
    },
    h3: {
      fontSize: '1.5rem', // 24px
      fontWeight: 500,
    },
    h4: {
      fontSize: '1.25rem', // 20px
      fontWeight: 500,
    },
    h5: {
      fontSize: '1.125rem', // 18px
      fontWeight: 400,
    },
    h6: {
      fontSize: '1rem', // 16px
      fontWeight: 400,
    },
    body1: {
      fontSize: '0.875rem', // 14px (normal body text)
      fontWeight: 400,
    },
    body2: {
      fontSize: '0.75rem', // 12px (smaller body text)
      fontWeight: 400,
    },
    button: {
      fontSize: '0.875rem', // 14px (standard button text size)
      fontWeight: 600,
    },
    subtitle1: {
      fontSize: '1rem', // 16px (subtitles)
      fontWeight: 500,
    },
    caption: {
      fontSize: '0.75rem', // 12px (for captions)
      fontWeight: 400,
    },
  },
  components: {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          '& .MuiDataGrid-cell:focus': {
            outline: 'none'
          },
          '& .MuiDataGrid-cell:focus-within': {
            outline: 'none'
          }
        },
      }
    },
  }
});

export default draculaTheme;
