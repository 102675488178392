import { alignProperty } from '@mui/material/styles/cssUtils';
import Phaser from 'phaser';

export class PowerUp extends Phaser.Physics.Arcade.Sprite {

    particles;
    onDestroy;



    constructor(scene, x, y, powerUpText = '🥳', movementEnabled = true, badPowerUp = false) {
        super(scene, x, y,);
        // Texture creation and texture names are now moved to PhaserGame
        // This constructor will receive the textureNames as a parameter
        this.textureNames = ['square-blue', 'square-cyan', 'square-green', 'square-indigo', 'square-lime', 'square-magenta', 'square-orange', 'square-pink', 'square-red', 'square-teal', 'square-violet', 'square-yellow']


        this.text = scene.add.text(
            this.x,
            this.y,
            powerUpText,
            {
                font: '105px Arial',
                align: 'center',
            })
            .setOrigin(0.5, 0.6)
            .setScale(0.4)

        this.setOrigin(0.5, 0.5)
        scene.add.existing(this);
        scene.physics.add.existing(this);
        scene.sys.updateList.add(this);
        this.setCollideWorldBounds(true);
        this.setBounce(0.5);
        this.setInteractive();
        const textDepth = badPowerUp ? 4 : 10;
        this.text.setDepth(textDepth);
        this.setXMoveDirection = -1;
        // Ensure the text is above the circle
        const startingColor = badPowerUp ? 'square-black' : 'square-red'
        const particleScale = badPowerUp ? 1 : 3;

        this.particles = this.scene.add.particles(0, 0, startingColor, {
            speed: 100,
            blendMode: 'NORMAL',
            lifespan: 100,
            maxAliveParticles: 100,
            scale: { start: 0, end: particleScale, ease: 'bounce.out' },
            quantity: 2,
        });
        const powerUpCloudDepth = badPowerUp ? 1 : 5;

        this.particles.setDepth(powerUpCloudDepth);
        this.particles.startFollow(this.text, 0, 0, true);


        let textureIndex = 1;
        this.scene.time.addEvent({
            delay: 600,
            callback: () => {
                textureIndex = (textureIndex + 1) % this.textureNames.length
                if (this.particles && !badPowerUp) {
                    this.particles?.setTexture(this.textureNames[textureIndex])
                }
            },
            loop: true,
        });





        this.on('pointerdown', () => {
            this.scene.tweens.add({
                targets: [this.particles, this.text],
                alpha: 0,
                duration: 500,
                onComplete: () => {
                    this.destroy();

                }
            });
        });


        this.getMovement = () => {
            const distance = 50 + (Math.random() * 200);

            let proposedX = this.x + (this.setXMoveDirection * distance);
            const proposedY = this.y - distance;
            let movement

            if (Math.abs(this.x - proposedX) < 200) {
                proposedX = this.x + (this.setXMoveDirection * 250);
            }

            const moveDirection = Math.random() > 0.5;
            if (moveDirection) {
                movement = {
                    x: proposedX,
                    y: this.y
                };
                this.setXMoveDirection = this.setXMoveDirection * -1;
            } else {
                movement = {
                    x: this.x,
                    y: Math.min(proposedY, this.y - 100)
                };
            }
            movement.rotation = Math.random() > 0.5 && movement.y < this.y ? 360 : 0;
            const duration = Math.random() > 0.5 ? 150 : Math.random() > 0.5 ? 750 : 1500;



            return { movement, duration };
        }

        this.addRandomMovementTween = () => {
            const moveUp = () => {
                const { movement, duration } = this.getMovement();
                if (!this || !this.active || !this.scene) {
                    return;
                }


                this.scene.tweens.add({
                    targets: [this.text],
                    x: movement.x,
                    y: movement.y,
                    duration: duration,
                    rotation: movement.rotation,
                    ease: 'Sine.easeOut',
                    yoyo: false,
                    onUpdate: () => {
                        if (this.text) {
                            this.setPosition(this.text.x, this.text.y);
                        }
                    },
                    onComplete: fallDown
                });
            };

            const fallDown = () => {
                if (!this || !this.active || !this.scene) {
                    return;
                }

                const groundY = this.scene.sys.game.config.height - 70; // Adjust this value as needed
                if (this.setXMoveDirection > 0) {
                    this.text.setFlipX(false);
                } else {
                    this.text.setFlipX(true);
                }
                this.scene.tweens.add({
                    targets: [this.text],
                    y: groundY,
                    duration: 350,
                    ease: 'Bounce.easeOut',
                    rotation: 0,

                    onUpdate: () => {
                        if (this.text) {
                            this.setPosition(this.text.x, this.text.y);
                        }
                    },
                    onComplete: () => {
                        this.scene.time.delayedCall((Math.random() * 2500) + 500, moveUp); // Wait for 1 second before moving up again
                    }
                });
            };

            fallDown(); // Start the cycle
        }

        this.addSpinningMovementTween = () => {
            const moveUp = () => {
                const { movement, duration } = this.getMovement();
                if (!this.scene) {
                    return;
                }

                this.scene.tweens.add({
                    targets: [this.text],
                    scaleX: 0.5,
                    scaleY: 0.55,
                    duration: 750,
                    ease: 'Sine.easeOut',
                    onUpdate: () => {
                        if (this.text) {
                            this.setPosition(this.text.x, this.text.y);
                        }
                    },
                    onComplete: fallDown
                });
            };

            const fallDown = () => {
                if (!this.scene) {
                    return;
                }

                const groundY = this.scene.sys.game.config.height - 70; // Adjust this value as needed
                this.scene.tweens.add({
                    targets: [this.text],
                    y: groundY,
                    scaleX: -0.5,
                    scaleY: 0.5,
                    duration: 750,
                    ease: 'Sine.easeOut',
                    onUpdate: () => {
                        if (this.text) {
                            this.setPosition(this.text.x, this.text.y);
                        }
                    },
                    onComplete: () => {
                        moveUp()// Wait for 1 second before moving up again
                    }
                });
            };

            fallDown(); // Start the cycle
        }
        if (movementEnabled) {
            this.addRandomMovementTween();
        } else {
            this.addSpinningMovementTween();
        }
    }

    getParticles() {
        return this.particles;
    }


    destroy(fromScene) {
        // Ensure the text is destroyed when the circle is destroyed
        if (this.text) {
            this.text.destroy();
            this.text = null;
        }

        if (this.particles) {
            this.particles.destroy();
            this.particles = null;
        }

        if (this.onDestroy) {
            this.onDestroy();
        }

        super.destroy(fromScene);
    }
}

export default PowerUp;
