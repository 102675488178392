import {
  useLocation,
  Navigate,
} from 'react-router-dom'
import { useSelector } from 'react-redux'

export default ({ children }) => {
  const username = useSelector(state => state.game.username)
  let location = useLocation();

  if (!username.length) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return children;
}
