import {
  Routes,
  Route,
} from "react-router-dom";
import Layout from '../containers/Layout'
import UserLogin from '../containers/UserLogin'
import RoomListPage from '../containers/RoomListPage'
import RoomPage from '../containers/RoomPage'
import RequireAuth from './RequireAuth'
import TermsOfUse from "../components/TermsOfUse";

export default () => {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path="/" element={<UserLogin />} />
        <Route path="/terms-of-use" element={<TermsOfUse />} />
        <Route
          path="/rooms"
          element={
            <RequireAuth>
              <RoomListPage />
            </RequireAuth>
          }
        />
        <Route
          path="/rooms/:roomName"
          element={
            <RequireAuth>
              <RoomPage />
            </RequireAuth>
          }
        />
      </Route>
    </Routes>
  )
}
